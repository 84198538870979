<template>
	<div class="">
		<div class="console mt-5">
			<div class="p1">
				<a-row>
					<a-col :span="12">
						<div class="item">
							<div class="d1"><span>累计注册人数</span></div>
							<div class="d2">{{ info.registerNumber }}</div>
						</div>
					</a-col>
					<a-col :span="12">
						<div class="item">
							<div class="d1"><span>最近三十天注册人数</span></div>
							<div class="d2">{{ info.monthUserNumber }}</div>
						</div>
					</a-col>
				</a-row>
			</div>
			<div class="p1">
				<a-row>
					<a-col :span="12">
						<div class="item">
							<div class="d1"><span>累计注销人数</span></div>
							<div class="d2">{{ info.cancellationCount }}</div>
						</div>
					</a-col>
					<a-col :span="12">
						<div class="item">
							<div style="display: flex;justify-content: space-between;align-items: center;">
								<div>
									<div class="d1"><span>当前在线人数</span></div>
									<div class="d2">{{ info.onlineNumber }}</div>
								</div>
								<div>
									<div class="d1"><span>退出登录人数</span></div>
									<div class="d2">{{ info.logOutNumber }}</div>
								</div>
							</div>


						</div>
					</a-col>
				</a-row>
			</div>
		</div>


		<!-- <div class="p2">
			<a-row>
				<a-col :span="24">
					<div class="item">
						<div class="chart"><ve-line title="" :data="item1"></ve-line></div>
					</div>
				</a-col>
			</a-row>
		</div> -->
		<div class="charts-box">
			<qiun-vue-ucharts type="line" :opts="opts" :chartData="chartData" />
		</div>
	</div>
</template>

<script>
import { getInfo, home } from "../../../api/index.js"
// import VeLine from "v-charts/lib/line.common"
// import VeRing from 'v-charts/lib/ring.common'
// import VeHistogram from 'v-charts/lib/histogram.common'
// import "echarts/lib/component/title"
import qiunVueUcharts from '@qiun/vue-ucharts'

export default {
	components: {
		// VeLine,
		// VeRing,
		// VeHistogram,
		qiunVueUcharts
	},
	methods: {
		async more(e) {
			let res = await getInfo()
			if (res.status == 200) {
				localStorage.setItem('user', res.data.user)
				this.user = res.data.user
			}
		},
		async gethome(e) {
			let res = await home()
			if (res.status == 200) {
				let time = []
				let arr = []
				this.info = res.data
				for (var i in this.info.map) {
					time.push(i)
					arr.push(this.info.map[i])
				}
				let str = {
					categories: time,
					series: [{
						name: "近30天注册人数",
						data: arr
					}]
				};
				this.chartData = str
			}
		},
		getServerData() {
			//模拟从服务器获取数据时的延时
			setTimeout(() => {
				//模拟服务器返回数据，如果数据格式和标准格式不同，需自行按下面的格式拼接
				let res = {
					categories: ["2018", "2019", "2020", "2021", "2022", "2023"],
					series: [
						{
							name: "成交量A",
							linearColor: [
								[
									0,
									"#1890FF"
								],
								[
									0.25,
									"#00B5FF"
								],
								[
									0.5,
									"#00D1ED"
								],
								[
									0.75,
									"#00E6BB"
								],
								[
									1,
									"#90F489"
								]
							],
							setShadow: [
								3,
								8,
								10,
								"#1890FF"
							],
							data: [15, 45, 15, 45, 15, 45]
						},
						{
							name: "成交量B",
							data: [55, 85, 55, 85, 55, 85]
						},
						{
							name: "成交量C",
							linearColor: [
								[
									0,
									"#FAC858"
								],
								[
									0.33,
									"#FFC371"
								],
								[
									0.66,
									"#FFC2B2"
								],
								[
									1,
									"#FA7D8D"
								]
							],
							setShadow: [
								3,
								8,
								10,
								"#FC8452"
							],
							data: [95, 125, 95, 125, 95, 125]
						}
					]
				};
				this.chartData = JSON.parse(JSON.stringify(res));
			}, 500);
		},
	},
	mounted() {
		// this.getServerData();
		this.more()
		this.gethome()
	},
	beforeCreate() {
	},
	data() {
		return {
			user: {},
			chartData: {},
			info: {},
			opts: {
				color: ["#1890FF", "#91CB74", "#FAC858", "#EE6666", "#73C0DE", "#3CA272", "#FC8452", "#9A60B4", "#ea7ccc"],
				padding: [15, 10, 0, 15],
				dataLabel: false,
				dataPointShape: false,
				enableScroll: false,
				legend: {},
				xAxis: {
					disableGrid: true
				},
				yAxis: {
					gridType: "dash",
					dashLength: 2,
					data: [
						{
							min: 0,
							max: 150
						}
					]
				},
				extra: {
					line: {
						type: "curve",
						width: 2,
						activeType: "hollow",
						linearType: "custom",
						onShadow: true,
						animation: "horizontal"
					}
				}
			}

		}
	}
};
</script>

<style scoped>
.console {
	padding-bottom: 30px;
}

.console .title {
	height: 50px;
	border-bottom: 1px solid #f0f0f0;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding: 0 24px;
}

.charts-box {
	/* width: 1000px; */
	height: 500px;
	background-color: #fff;
	z-index: 99999;
	padding: 20px 0;
}

.console .title div:nth-child(1) {
	flex: 1;
}

.console .title div:nth-child(2) span {
	font-size: 14px;
	margin-left: 15px;
}

.console .chart {
	padding: 30px 20px 0
}

.console .p1,
.console .p2,
.console .p3 {
	margin: 0 -12px 24px;
}

.console .p1 .item {
	height: 180px;
	background: #FFFFFF;
	margin: 0 12px;
	border-radius: 2px;
	padding: 20px 24px 8px
}

.console .p1 .d1 {
	height: 22px;
	color: rgba(0, 0, 0, .45);
	font-size: 14px;
	line-height: 22px;
}

.console .p1 .d2 {
	height: 38px;
	margin-top: 4px;
	overflow: hidden;
	color: rgba(0, 0, 0, .85);
	font-size: 30px;
	line-height: 38px;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
}

.console .p1 .d3 {
	height: 50px;
}

.console .p1 .d4 {
	padding-top: 10px;
	border-top: 1px solid #f0f0f0;
}

.console .p2 .item {
	height: 410px;
	background: #FFFFFF;
	margin: 0 12px;
	border-radius: 2px;
}

.console .p3 .item {
	height: 510px;
	background: #FFFFFF;
	margin: 0 12px;
	border-radius: 2px;
}
</style>